import styled from "styled-components";

export const Wrapper = styled.h3`
  font-family: "giveny", serif;
  font-size: 36px;
  text-transform: uppercase;

  ${({ theme }) => theme.media.tablet} {
    font-size: 42px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 52px;
  }

  ${({ theme }) => theme.media.tv} {
    font-size: 64px;
  }
`;
