import styled from "styled-components";

export const Wrapper = styled.section`
  margin: 0 auto;
  padding: 180px 0 350px;
  width: 100%;
  text-align: center;

  > h3 {
    margin: 10vh;
  }

  > p {
    font-size: 18px;
    margin: 0 0 20px;
    text-transform: uppercase;
  }

  ${({ theme }) => theme.media.tablet} {
    padding: 230px 0 375px;

    > p {
      font-size: 20px;
      margin: 0 0 50px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 280px 0 400px;

    > p {
      font-size: 22px;
      margin: 0 0 60px;
    }
  }

  ${({ theme }) => theme.media.tv} {
    padding: 380px 0 450px;

    > p {
      font-size: 24px;
      margin: 0 0 70px;
    }
  }
`;
