import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles.js";

const Blank = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

Blank.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Blank;
