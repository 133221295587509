import styled from "styled-components";

export const Wrapper = styled.button`
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 20px 30px;
  background: ${({ theme, color }) => theme.colors[color]};
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;

  > span {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 0;
    height: 60%;
    border-radius: 80px;
    background: ${({ theme }) => theme.colors.black};
    transition: transform 0.05s ease, width 0.3s ease;
  }

  > span:nth-child(1) {
    bottom: -5%;
    right: -15%;
    transform-origin: 50% 0%;
    transform: rotate(2deg);
    transition-delay: 0.25s;
  }

  > span:nth-child(2) {
    right: auto;
    bottom: 25%;
    left: -15%;
    transform-origin: 50% 100%;
    transform: rotate(-3deg);
    transition-delay: 0.125s;
  }

  > span:nth-child(3) {
    bottom: 55%;
    right: -20%;
    transform-origin: 50% 0%;
    transform: rotate(3deg);
    transition-delay: 0s;
  }

  p {
    font-family: "giveny", serif;
    color: ${({ theme, color }) =>
      color === "black" || color === "red"
        ? theme.colors.white
        : theme.colors.black};
  }

  ${({ active }) =>
    active &&
    `
      > span:nth-child(1) {
        width: 150%;
        transition-delay: 0s;
      }

      > span:nth-child(2) {
        width: 150%;
        transition-delay: 0.125s;
      }

      > span:nth-child(3) {
        width: 150%;
        transition-delay: 0.25s;
      }

      p {
        color: white;
      }
    `}

  ${({ hoverAnimation }) =>
    hoverAnimation &&
    `
    &:hover {
      > span:nth-child(1) {
        width: 150%;
        transition-delay: 0s;
      }

      > span:nth-child(2) {
        width: 150%;
        transition-delay: 0.125s;
      }

      > span:nth-child(3) {
        width: 150%;
        transition-delay: 0.25s;
      }

      p {
        color: white;

        span {
          color: white !important;
        }
      }
    }`}

    ${({ pulse }) =>
    pulse &&
    `
    animation-name: pulseButton;
    animation-duration: 6s;
    animation-delay: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    @keyframes pulseButton {
      0% {
        transform: scale(1);
      }
      4% {
        transform: scale(1.08);
      }
      8% {
        transform: scale(1);
      }
      12% {
        transform: scale(1.08);
      }
      16% {
        transform: scale(1);
      }
      20% {
        transform: scale(1.08);
      }
      24% {
        transform: scale(1);
      }
    }`}

  ${({ theme }) => theme.media.tablet} {
    padding: 25px 35px;
    font-size: 20px;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 28px 45px;
    font-size: 22px;
  }

  ${({ theme }) => theme.media.tv} {
    padding: 30px 50px;
    font-size: 24px;
  }
`;

export const Label = styled.p`
  position: relative;
  display: inline-block;
  color: black;
  transition: color 0.15s ease;
  transition-delay: 0.1s;
`;
