import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const TextHeader = ({ text }) => {
  return <Wrapper>{text}</Wrapper>;
};

TextHeader.propTypes = {
  text: PropTypes.string,
};

TextHeader.defaultProps = {
  text: "",
};

export default TextHeader;
