import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Label } from "./styles.js";

const Button = ({
  active,
  color,
  pulse,
  hoverAnimation,
  onMouseOver,
  onMouseLeave,
  onClick,
  children,
}) => {
  const handleMouseOver = () => {
    onMouseOver && onMouseOver();
  };

  const handleMouseLeave = () => {
    onMouseLeave && onMouseLeave();
  };

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Wrapper
      active={active}
      color={color}
      pulse={pulse}
      hoverAnimation={hoverAnimation}
      onMouseOver={() => handleMouseOver()}
      onMouseLeave={() => handleMouseLeave()}
      onClick={() => handleClick()}
    >
      <span></span>
      <span></span>
      <span></span>
      <Label>{children}</Label>
    </Wrapper>
  );
};

Button.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.oneOf(["ghost", "black", "black2", "seashell", "seashell2", "red"]),
  pulse: PropTypes.bool,
  hoverAnimation: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Button.defaultProps = {
  active: false,
  color: "ghost",
  pulse: false,
  hoverAnimation: false,
  children: null,
};

export default Button;
